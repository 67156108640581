small {
  color: $grey-color;
}

// Import partials.
@import
  "avatars",
  "links",
  "whiteglass/base",
  "whiteglass/layout",
  "whiteglass/syntax-highlighting"
;
