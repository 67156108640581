.avatars
  @extend %vertical-rhythm
  align-items: center
  display: flex
  justify-content: space-around

  img
    @include media-query($on-palm)
      max-height: 150px
      max-width: 150px

  picture
    width: 200px

.avatars-arrow
  text-align: center
  width: 50px

.avatars-railsclub
  border-radius: 50%
  transform: scale(.8)
